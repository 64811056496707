import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './FileUpload.css';

const FileUpload = ({ companyToken }) => {
  const [message, setMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState('');
  const [progress, setProgress] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [results, setResults] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file || (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel')) {
      setMessage('Por favor, selecciona un archivo Excel válido.');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const processedJsonArray = jsonData.map(row => {
        const firstFeePaidDate = new Date();
        const ivaValue = 0.19; // Valor fijo del IVA

        return {
          documentType: 'XGDVELECT',
          firstFolio: 0,
          lastFolio: 0,
          externalDocumentID: '',
          emissionDate: {
            day: row['DiaEmision'] || 0,
            month: row['MesEmision'] || 0,
            year: row['AnoEmision'] || 0,
          },
          firstFeePaid: {
            day: firstFeePaidDate.getDate(),
            month: firstFeePaidDate.getMonth() + 1,
            year: firstFeePaidDate.getFullYear(),
          },
          clientFile: row['RutCliente'] || '',
          contactIndex: row['Direccion'] || '',
          paymentCondition: 'CREDITO30',
          sellerFileId: 'CENABAST',
          clientAnalysis: {
            accountNumber: '1110401001',
            businessCenter: '',
            classifier01: '',
            classifier02: '',
          },
          billingCoin: 'PESO',
          billingRate: 1,
          shopId: 'LOCAL',
          priceList: '1',
          giro: row['Giro'] || '',
          district: row['Comuna'] || '',
          city: row['Ciudad'] || '',
          contact: '-1',
          attachedDocuments: [
            {
              date: {
                day: row['DiaDocumentoAdjunto1'] || 0,
                month: row['MesDocumentoAdjunto1'] || 0,
                year: row['AnoDocumentoAdjunto1'] || 0,
              },
              documentTypeId: String(row['IdDocumento1'] || ''),
              folio: String(row['FolioDocumento1'] || ''),
              reason: null,
            },
            {
              date: {
                day: row['DiaDocumentoAdjunto2'] || 0,
                month: row['MesDocumentoAdjunto2'] || 0,
                year: row['AnoDocumentoAdjunto2'] || 0,
              },
              documentTypeId: String(row['IdDocumento2'] || ''),
              folio: String(row['FolioDocumento2'] || ''),
              reason: null,
            },
            {
              date: {
                day: row['DiaDocumentoAdjunto3'] || 0,
                month: row['MesDocumentoAdjunto3'] || 0,
                year: row['AnoDocumentoAdjunto3'] || 0,
              },
              documentTypeId: String(row['IdDocumento3'] || ''),
              folio: String(row['FolioDocumento3'] || ''),
              reason: null,
            },
            {
              date: {
                day: row['DiaDocumentoAdjunto4'] || 0,
                month: row['MesDocumentoAdjunto4'] || 0,
                year: row['AnoDocumentoAdjunto4'] || 0,
              },
              documentTypeId: String(row['IdDocumento4'] || ''),
              folio: String(row['FolioDocumento4'] || ''),
              reason: null,
            },
          ],
          originStorage: {
            code: 'CASABLANCA',
            motive: 'VENTA',
            storageAnalysis: {
              accountNumber: '4110101001',
              businessCenter: 'EMPNEGGIRMED000',
              classifier01: '',
              classifier02: '',
            },
          },
          destinationStorage: {
            code: 'CASABLANCA',
            motive: 'VENTA',
            storageAnalysis: {
              accountNumber: '4110101001',
              businessCenter: 'EMPNEGGIRMED000',
              classifier01: '',
              classifier02: '',
            },
          },
          dispatchInfo: {
            assetsType: '1',
            dispatchType: '2',
            transactionType: '1',
            isTransferDispatch: false,
          },
          details: [{
            type: 'A',
            isExempt: false,
            code: row['CodigoProducto'] || '',
            count: row['Cantidad'] || 0,
            productName: '',
            productNameBarCode: '',
            price: row['Precio'] || 0,
            comment: row['Comentario'] || '',
            discount: {
              type: 0,
              value: 0,
            },
            unit: row['Unidad'] || '',
            analysis: {
              accountNumber: '1110801001',
              businessCenter: '',
              classifier01: '',
              classifier02: '',
            },
            useBatch: true,
            batchInfo: [{
              amount: row['Cantidad'] || 0,
              batchNumber: row['Lote'] || '',
            }],
            useSeries: false,
          }],
          saleTaxes: [{
            code: 'IVA',
            value: ivaValue,
            taxAnalysis: {
              accountNumber: '',
              businessCenter: '',
              classifier01: '',
              classifier02: '',
            }
          }],
          gloss: '',
          customFields: [],
          ventaRecDesGlobal: [],
          isTransferDocument: false,
        };
      });

      setTotalRecords(processedJsonArray.length);
      setProgress(0);

      let resultsArray = [];

      try {
        for (let i = 0; i < processedJsonArray.length; i++) {
          const data = processedJsonArray[i];
          const response = await axios.post('https://api.defontana.com/api/Dispatch/Save', data, {
            headers: {
              'Authorization': `Bearer ${companyToken}`
            }
          });

          const row = jsonData[i];  // Asegúrate de obtener la fila correcta de jsonData
          const nombreCliente = row['NombreCliente'] || '';

          resultsArray.push({
            Folio: response.data.firstFolio,
            Estado: response.data.success,
            FolioDocumento2: data.attachedDocuments[1].folio,
            NombreCliente: nombreCliente,
            RutCliente: data.clientFile,
            Mensaje: response.data.message
          });

          setProgress(prevProgress => prevProgress + 1);
        }

        setMessage('¡Se han enviado los registros! Revisar JSON de salida para verificar');
        setErrorDetails('');
      } catch (error) {
        console.error('Error:', error);
        setMessage('Ha ocurrido un problema al enviar los registros');
        setErrorDetails(error.response ? JSON.stringify(error.response.data) : error.message);
      }

      // Generar y descargar el archivo JSON de resultados
      const jsonOutput = JSON.stringify(resultsArray, null, 2);
      const blob = new Blob([jsonOutput], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'registrosEnviados.json';
      a.click();
      URL.revokeObjectURL(url);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="FileUpload">
      <input type="file" onChange={handleFileUpload} />
      <div className="progress">
        <div
          className={`progress-bar ${progress === 0 ? 'initial' : 'active'}`}
          style={{ width: `${(progress / totalRecords) * 100}%` }}
        >
          {progress} de {totalRecords} registros enviados
        </div>
      </div>
      <div>
        {message && <div className="message">{message}</div>}
        {errorDetails && <div className="error-details">{errorDetails}</div>}
      </div>
    </div>
  );
};

export default FileUpload;
