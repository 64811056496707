import React, { useState } from 'react';
import FileUpload from './FileUpload';
import './App.css';

const App = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyToken, setCompanyToken] = useState('');

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleTokenChange = (event) => {
    setCompanyToken(event.target.value);
  };

  return (
    <div className="App">
        <div className="card">
        <img src={`${process.env.PUBLIC_URL}/LogoFLEXING2.png`} alt="Logo" className="logo" />
          <h1>Emisión de Guías de Despacho</h1>
          <div>
            <select value={selectedCompany} onChange={handleCompanyChange}>
              <option value="">Seleccione una empresa</option>
              <option value="empresa1">Flexing</option>
              <option value="empresa2">IC Global</option>
            </select>
          </div>
          {selectedCompany && (
            <div>
              <label>Ingrese el token de la empresa:</label>
              <input type="text" value={companyToken} onChange={handleTokenChange} />
            </div>
        )}
          <FileUpload selectedCompany={selectedCompany} companyToken={companyToken} />
        </div>
      </div>
    );
  };

export default App;
